import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./components/mintBox";
import IndexPage from './pages/index';
import React from "react";
import './App.css'
import { MAX_BATCH_SIZE} from "./utils/configuration";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      provider: {},
      web3: {},
      account: {},
      mintAmout: MAX_BATCH_SIZE,
      mintType: "",
      mintTransaction: "",
      pendingRequest: false,
      connected: false,
      txn: "",
      finish: false,
      mintedNum: 1,
      tier: 0,
      address: "",
      balance:null,
      containedModalShow: "",
      modalDialogTitle: "",
      modalDialogBodyText: "",
      modalDialogBodyHref: "",
      mintErrorMsg: "",
      isWhiteList: false,
      proof:[],
      percentage:""

    };
    this.web3Modal = {};
  }

  componentDidMount() {
    document.body.style.background = '#12232e'
  }


  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<IndexPage  data={this}/>} />
        </Routes>
      </Router>
    );
  }
}

export default App;
