import React from 'react';
import { onConnect } from "../../utils/wallet";
import { formatWalletAddress } from "../../utils/util";
import "./index.css";
import Web3Modal from "web3modal";
import { useEffect, useState } from "react";
import WalletLink from "walletlink";
import WalletConnectProvider from "@walletconnect/web3-provider";
import p6 from "../../assets/p6.png";
import p5 from "../../assets/p5.png";
import p7 from "../../assets/p7.png";
import p4 from "../../assets/p4.png";
import p2 from "../../assets/p2.png";
import twitter from "../../assets/twitter.gif";
import cardImg from "../../assets/card-img.gif";
import p1 from "../../assets/cat2.gif";
import headTwitter from "../../assets/twitter.png";
import headOpensea from "../../assets/opensea-head.png";
import qix from "../../assets/qix.png";
import { getMintedRecur} from "../../utils/status";
import { MintTransaction,MintToWhiteList } from "../../utils/mint";
import { MAX_AMOUNT,MAX_BATCH_SIZE,PRICE,CHAIN_ID} from "../../utils/configuration";
import { ModalDialog } from "../../shared-components/component";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import dextools from "../../assets/dextools.png";
import nftscan2 from "../../assets/nftscan2.png";
import quix from "../../assets/quix.png";
import imtoken from "../../assets/imtoken.png";
import math from "../../assets/math.png";
import tp from "../../assets/tp.png";
import bitpie from "../../assets/bitpie.png";
import opensea from "../../assets/opensea.png";
import opt from "../../assets/opt.png";
import { height } from '@mui/system';






const ctx = require.context("../../assets/slider-imgs", true, /.png$/);
let images = [];
ctx.keys().forEach((key) => {
  images.push(ctx(key));
});


const providerOptions = {
  walletlink: {
    package: WalletLink, // Required
    options: {
      appName: "Crypto Angel", // Required
      infuraId: "INFURA_ID", // Required unless you provide a JSON RPC url; see `rpc` below
      rpc: "", // Optional if `infuraId` is provided; otherwise it's required
      chainId: 1, // Optional. It defaults to 1 if not provided
      appLogoUrl: null, // Optional. Application logo image URL. favicon is used if unspecified
      darkMode: false, // Optional. Use dark theme, defaults to false
    },
  },
  walletconnect: {
    display: {
      name: "Mobile",
    },
    package: WalletConnectProvider,
    options: {
      infuraId: "INFURA_ID", // required
    },
  },
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const IndexPage = ({ data }) => {

  data.web3Modal = new Web3Modal({
    network: "mainnet",
    cacheProvider: true,
    providerOptions: providerOptions,
  });

  const [count, setCount] = useState(1);






const NumberDiv = function (arg1,arg2,digit){
  var t1=0,t2=0,r1,r2;
  try{t1=arg1.toString().split(".")[1].length}catch(e){}
  try{t2=arg2.toString().split(".")[1].length}catch(e){}
  r1=Number(arg1.toString().replace(".",""))
  r2=Number(arg2.toString().replace(".",""))
  //获取小数点后的计算值
 var result= ((r1/r2)*Math.pow(10,t2-t1)).toString()
  var result2=result.split(".")[1];
  if(result2==null){
      result2 = "";
  }
  result2=result2.substring(0,digit>result2.length?result2.length:digit);

  return Number(result.split(".")[0]+"."+result2);
}

  function setMax(){
    var max = MAX_BATCH_SIZE
    data.setState({
      mintAmout: data.state.balance==null?1:max
  });
   }
   function remove(){
    var amount = --data.state.mintAmout;
    data.setState({
      mintAmout: data.state.balance==null?1:amount<=1?1:amount,
  });
   }

   function add(){
    var amount = ++data.state.mintAmout;
    var max = MAX_BATCH_SIZE
    amount = amount>max?max:amount
    data.setState({
      mintAmout: data.state.balance==null?1:amount
  });
}

  useEffect(() => {
    (async () => {
      getMintedRecur(data)
      if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER"))
        await onConnect(data);

    })();
  }, [data]);


  const getSellOupt = (e) => {
    var len1 = e.target.value.substr(0, 1);
    var text = /^[0-9]+$/

    if(e.target.value==="")
    {
    data.setState({
      mintAmout:e.target.value,
     });
     }else if (len1 === "0") {
      data.setState({
        mintAmout:"1",
       });
     } 
     else if (e.target.value>MAX_BATCH_SIZE) {
      data.setState({
        mintAmout:MAX_BATCH_SIZE,
       });
     }else 
     if(text.test(e.target.value))
    {
    data.setState({
      mintAmout:e.target.value,
  });
    }
   }

  return (
    <div className="home-container">
      <div  className={"home-head-wrapper"}>
      <img src={p2} className="head-section-img" />


      {/* <div className={"head-section-title"}>CRYPTO ANGEL</div> */}
    <div>
    {data.state.address ? (
      <button className="head-button"  data={data}
      disabled={true}
     >Connected: {formatWalletAddress(data.state.address)}</button>
            ): (
              <button className="head-button"  data={data}
              onClick={() => onConnect(data)}
             >CONNECT WALLET</button>
            )}

    </div>
    <div>
    <a href="https://qx.app/collection/0x375e1114FEf565e7FD343D25f39C4bB1893dCb8f" target="_blank" rel="noreferrer"><img src={qix} alt='' className="head-discord"/></a>
    </div>
    <div>
    <a href="https://twitter.com/cryptoangel_s" target="_blank" rel="noreferrer"><img src={headTwitter} alt='' className="head-opensea"/></a>
    </div>
    <div>
    <a href="https://opensea.io/collection/cryptoangel-v4" target="_blank" rel="noreferrer"><img src={headOpensea} alt='' className="head-twitter"/></a>
    </div>
    </div>
      <nav className={"navbar  navbar-inverse"}></nav>
      <div className={"main-section"}>
        <div className={"main-section-title"}>CRYPTO ANGEL</div>
        <div className={"main-section-title-mobile"}>CRYPTO</div>
        <div className={"main-section-subtitle-mobile"}>ANGEL</div>
        <div style={{ width:"100%"}}>
        <div className="newlanding-mint-container">
          <div className="mint-logo-container mint-instruction ml-[-1.5vw]">
            <img src={cardImg} className="mint-logo w-[150px]" />
            <div className="mint-bg mt-[75px] mb-[60px] w-[85%]">
              <div className="progress-bar-container">
                <div className="progress-bar text-center text-white">
                  <span className="progress-bar-fill  h-full" style={{width:data.state.percentage}}></span>
                  <span className="supply-minted">{data.state.mintedNum}/{MAX_AMOUNT} MINTED</span>
                </div>
              </div>
              <div className="mt-[20px]">
              <Tooltip title="Minus" arrow placement="top">
                <button
                  className="plus-button"
                  onClick={() => remove()}
                >
                  -
                </button>
                </Tooltip>
                &nbsp;
                <input
                  type="text"
                  value={data.state.mintAmout}
                  className="w-[40px] font-inner"
                  onInput={getSellOupt} 
                  onChange={getSellOupt}
                />
                &nbsp;
                <Tooltip title="Add" arrow placement="top">
                <button
                  className="plus-button"
                  onClick={() => add()}
                >
                  +
                </button>
                </Tooltip>
                <Tooltip title="Set Maximum Amount" arrow placement="top">
                <a className="max  ml-[1vw]"  onClick={() => setMax()}>Max</a>
                </Tooltip>
              </div>
              <div className="mt-[30px]">
                <span className="mint_font">Total Cost</span>&nbsp; &nbsp;
                <input type="text" disabled value={NumberDiv(data.state.mintAmout,500,3)} className="w-[80px]" />
                &nbsp; &nbsp;
                <span className="mint_font">ETH</span>
              </div>
              {(data.state.connected===true&&data.state.networkId===CHAIN_ID&&data.state.address!=="")?
              <Tooltip title="Mint for your CryptoAngels !" arrow placement="top">
              <Button
                className="mint-btn mt-[40px]"
                variant="contained"
                size="large"
                style={{color:"white",background:"#c3c7ff", borderRadius: "1rem", border: "2px solid rgba(0, 0, 0, 0.944)"
              }}
                onClick={() => {
                  MintTransaction(data);
                  data.setState({
                    containedModalShow: true,
                    modalDialogBodyText: `Please approve in your wallet ......`
                  })
                }}
              >
                Mint
              </Button>
              </Tooltip>:
               <Tooltip title="Please select optimistic chain in your wallet" arrow placement="top">
               <div className="mint-disable-style mt-[40px]">
             <Button
               className="mint-disable-btn"
               variant="contained"
               disabled
               style={{color:"yellow",background:"#c4c4cf", borderRadius: "1rem", border: "2px solid rgba(0, 0, 0, 0.944)"}}
               size="large"
              >
               Please Select Optimism !
             </Button>
             </div>
             </Tooltip>}
              {/* {(data.state.isWhiteList&&data.state.networkId===CHAIN_ID&&data.state.address!=="")?
                            <Tooltip title="Congratulations! You can mint one CryptoAngel for free." arrow placement="top">
                            <Button
                              className="mint-btn mt-[40px]"
                              variant="contained"
                              disabled={!data.state.isWhiteList}
                              size="large"
                              style={{color:"white",background:"#6adccb", borderRadius: "1rem", border: "2px solid rgba(0, 0, 0, 0.944)"}}
                              onClick={() => {
                                MintToWhiteList(data);
                                data.setState({
                                  containedModalShow: true,
                                  modalDialogBodyText: `Please approve in your wallet ......`
                                })
                              }}              >
                              Free Mint
                            </Button>
                            </Tooltip>:
               <Tooltip title="Sorry! You are not in the white list." arrow placement="top">
                <div className="mint-disable-style mt-[40px]">
              <Button
                className="mint-disable-btn"
                variant="contained"
                disabled={!data.state.isWhiteList}
                style={{color:"white",background:"#c4c4cf", borderRadius: "1rem", border: "2px solid rgba(0, 0, 0, 0.944)"}}
                size="large"
               >
                Free Mint
              </Button>
              </div>
              </Tooltip>} */}

            </div>
          </div>
          {/* <img src={p1} className="mint-pic ml-[1vw]" />  */}
          {data.state.containedModalShow && (
        <ModalDialog
          title={data.state.modalDialogTitle}
          bodyText={
            data.state.mintErrorMsg
              ? data.state.mintErrorMsg
              : data.state.modalDialogBodyText
          }
          bodyHref={data.state.modalDialogBodyHref}
          bodyTxn={data.state.txn}
          show={data.state.containedModalShow}
          showPendingIcons={data.state.pendingRequest}
          onHide={() => {
            data.setState({
              containedModalShow: false,
              modalDialogTitle: "",
              modalDialogBodyText: "",
              pendingRequest: false,
              mintErrorMsg: "",
              modalDialogBodyHref: "",
            });
          }}
          state={data.state}
        />
      )}
        </div>
        </div>
        {/* <div className="para-container">
          <img src={p2} className={"img-flyingbuddies "} alt="" />
          <div className="div-para1">
            <span className="div-title">Customize</span>
            <span className="div-content">
              The Brain Buddies are redefining the meaning of generative art.
              We're giving you the tools to design an NFT that you're proud to
              call yours. With over 430 traits to choose from, things are bound
              to get wild and freaky. Take matters into your own hands. Let your
              creative juices flow.
            </span>
          </div>
        </div> */}
        {/* <div className="slider-container-div">
          <div className="slider-mask">
            <ul className="slider-container">
              {images.map((item, i) => (
                <li key={i}>
                  <img src={item} alt="" />
                </li>
              ))}
            </ul>
            <ul className="slider-container secondary">
              {images.reverse().map((item, i) => (
                <li key={i}>
                  <img src={item} alt="" />
                </li>
              ))}
            </ul>
          </div>
        </div> */}
        <div className="para-container">
          <div className="div-para2">
          <div className="twitter-container ">
              <img src={p5} className="accessory-img_1" />
            </div>
            {/* <div className="twitter-container">
              <img src={twitter} className="twitter-img" />
              <a href="https://twitter.com/BrainBuddiesNFT">
                <button className="twitter-btn">Follow us on Twitter</button>
              </a>
            </div> */}
            {/* <img src={twitter} className="twitter-img-mobile" /> */}
            <div className="div-para2-text ">
              <span className="div-title">Instruction</span>
              <span className="div-content">
              Crypto Angel is a collection with 10000 NFT on optimism.  
              Optimistic, beautiful and brave are the characteristics 
              of all Crypto Angel! It's not just an NFT project, it's a DAO. 
              </span>
            </div>
          </div>
        </div>
        <div className="para-container">
          <div className="div-para3">
            <div className="div-para3-text ">
              <span className="div-title">Community</span>
              <span className="div-content">
              The NFT is your incarnation in the metaverse and plays 
              a very important role in the future distribution of DAO tokens.
              The DAO governance token allocation scheme is coming soon.
              </span>
            </div>
            <div className="twitter-container ">
              <img src={p4} className="accessory-img_2" />
            </div>
          </div>
        </div>
        <div className="main-question-title" id="FAQ">
          FREQUENTLY ASKED QUESTIONS
        </div>
        <div style={{ width:"100%"}}>
        <div className="w-full flex flex-col items-center">
          <div className="tab">
            <input type="checkbox" id="chck2" />
            <label className="tab-label" htmlFor="chck2">
              How do I get a Crypto Angel?
            </label>
            <div className="tab-content">
            You can mint Crypto Angel on this website. Once the 10,000 Crypto Angels are minted, 
            you can only buy a Crypto Angel in the <a style={{color:"#eab918"}} href="https://qx.app/collection/0x375e1114FEf565e7FD343D25f39C4bB1893dCb8f" target="_blank" rel="noreferrer">Quix</a>  market.
            
            </div>
          </div>
          <div className="tab">
            <input type="checkbox" id="chck3" />
            <label className="tab-label" htmlFor="chck3">
              What's the smart contract of CryptoAngel ?
            </label>
            <div className="tab-content">
              The address of CryptoAngel's contract is <a style={{color:"#eab918"}}  href="https://optimistic.etherscan.io/address/0x375e1114fef565e7fd343d25f39c4bb1893dcb8f" target="_blank" rel="noreferrer">0x375e1114fef565e7fd343d25f39c4bb1893dcb8f</a> on optimism, 
              you can view optimism's blockchain explorer for details.
            </div>
          </div>
          <div className="tab">
            <input type="checkbox" id="chck4" />
            <label className="tab-label" htmlFor="chck4">
            How much does Mint Crypto Angel cost?
            </label>
            <div className="tab-content">
            Every whitelisted addresse can mint one Crypto Angel for free.
            Public mint costs 0.002 eth for each.
            </div>
          </div>
          <div className="tab">
            <input type="checkbox" id="chck5" />
            <label className="tab-label" htmlFor="chck5">
             How do I get on the guaranteed mint whitelist            
             </label>
            <div className="tab-content">
              You can<a style={{color:"#eab918"}}  href="https://docs.google.com/spreadsheets/d/1wwhkwCsJGRiacT9wDtWLgLfqSxM1YWzJ-5NM50m0ci8" target="_blank" rel="noreferrer"> click here </a> to check if your address is on the whitelist. 
            </div>
          </div>
          <div className="tab">
            <input type="checkbox" id="chck6" />
            <label className="tab-label" htmlFor="chck6">
             What rights do I have over my Crypto Angel?
            </label>
            <div className="tab-content">
            The Crypto Angel NFT is your incarnation in the metaverse and plays a 
            very important role in the future distribution of DAO tokens.
            </div>
          </div>
          <div className="tab">
            <input type="checkbox" id="chck7" />
            <label className="tab-label" htmlFor="chck7">
            What is optimism?
            </label>
            <div className="tab-content">
            Optimism is a fast, stable, and scalable layer 2 blockchain
             built by Ethereum developers.
              </div>
          </div>
          <div className="tab">
            <input type="checkbox" id="chck8" />
            <label className="tab-label" htmlFor="chck8">
            Where can I connect with the community?
            </label>
            <div className="tab-content">
            {/* Join our <a href="https://discord.gg/qU4UK8k5xR">Discord</a> or */}
              Follow us on{" "}
              <a style={{color:"#eab918"}}  href="https://twitter.com/cryptoangel_s" target="_blank" rel="noreferrer">Twitter</a>. We'll
              be waiting for you!            </div>
          </div>
          </div>
          </div>


{/* 
           <div className="slider-container-div">
          <div className="slider-mask">
            <ul className="slider-container">
              {images.map((item, i) => (
                <li key={i}>
                  <img src={item} alt="" />
                </li>
              ))}
            </ul>
            <ul className="slider-container secondary">
              {images.reverse().map((item, i) => (
                <li key={i}>
                  <img src={item} alt="" />
                </li>
              ))}
            </ul>
          </div>
        </div>   */}
          {/* <img src={p7} className="footer" />
          <img src={p6} className="footer-mobile" /> */}
                  <div className="main-parnters-title" id="FAQ">
          Parnters
        </div>
        <div className="flex_container_partners">
        <img src={opt} loading="lazy"/>
        <img src={quix} loading="lazy"/>
        <img src={opensea} loading="lazy"/>
        <img src={imtoken} loading="lazy"/>
        <img src={tp} loading="lazy"/>
        </div>
        <div className="flex_container_partners">
        <img src={math} loading="lazy"/>
        <img src={bitpie} loading="lazy"/>
        <img src={nftscan2} loading="lazy"/>
        <img src={dextools} loading="lazy"/>
        </div>
        <div className="flex_container_partners-mobile">
        <img src={opt} loading="lazy"/>
        <img src={quix} loading="lazy"/>
        <img src={opensea} loading="lazy"/>
        </div>
        <div className="flex_container_partners-mobile">
        <img src={math} loading="lazy"/>
        <img src={imtoken} loading="lazy"/>
        <img src={tp} loading="lazy"/>
        </div>
        <div className="flex_container_partners-mobile">
        <img src={bitpie} loading="lazy"/>
        <img src={nftscan2} loading="lazy"/>
        <img src={dextools} loading="lazy"/>
        </div>
        <div className="slider-container-div">
          <div className="slider-mask">
            <ul className="slider-container secondary">
              {images.reverse().map((item, i) => (
                <li key={i}>
                  <img src={item} style={{height:"200px",width:"200px"}} alt="" />
                </li>
              ))}
            </ul>
          </div>

        </div>
        <div  className={"footer-style"}>
      <div className={"footer-section-title"}></div>
    <div>
    <div className={"footer-section-title"}>© 2022 CryptoAngel. All rights reserved.</div>
    </div>
    <div  className={"footer-media"}>
    <a href="https://twitter.com/cryptoangel_s" target="_blank" rel="noreferrer" className={"footer-section-title-media"} style={{color:"#eab918"}}>Twitter</a>
    <a href="https://optimistic.etherscan.io/address/0x375e1114fef565e7fd343d25f39c4bb1893dcb8f" target="_blank" rel="noreferrer" className={"footer-section-title-media"} style={{color:"#eab918"}}>Optimism</a>
    <a href="https://qx.app/collection/0x375e1114FEf565e7FD343D25f39C4bB1893dCb8f" target="_blank" rel="noreferrer" className={"footer-section-title-media"} style={{color:"#eab918"}}>quix</a>
    <a href="https://opensea.io/collection/cryptoangel-v4" target="_blank" rel="noreferrer" className={"footer-section-title-media"} style={{color:"#eab918"}}>opensea</a>
    </div>
    </div> 
    <div  className={"footer-style-mobile"}>
    <div  className={"footer-media-mobile"}>
    <a href="https://twitter.com/cryptoangel_s" target="_blank" rel="noreferrer" className={"footer-section-title-media-mobile"} style={{color:"#eab918"}}>Twitter</a>
    <a href="https://optimistic.etherscan.io/address/0x375e1114fef565e7fd343d25f39c4bb1893dcb8f" target="_blank" rel="noreferrer" className={"footer-section-title-media-mobile"} style={{color:"#eab918"}}>Optimism</a>
    <a href="https://qx.app/collection/0x375e1114FEf565e7FD343D25f39C4bB1893dCb8f" target="_blank" rel="noreferrer" className={"footer-section-title-media-mobile"} style={{color:"#eab918"}}>quix</a>
    <a href="https://opensea.io/collection/cryptoangel-v4" target="_blank" rel="noreferrer" className={"footer-section-title-media-mobile"} style={{color:"#eab918"}}>opensea</a>
    </div>
    <div>
    <div className={"footer-section-title-mobile"}>© 2022 CryptoAngel. All rights reserved.</div>
    </div>

    </div> 
      </div>

    </div>
  );
};

export default IndexPage;
