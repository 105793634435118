import Web3 from "web3";
import { getMintStatus, getMintedRecur,getMinted,getMinted_move,balanceOfCryptoAngel,getPercentageOfMint } from "./status";
import { getTree } from "./merkleTree";

/**
 * Kick in the UI action after Web3modal dialog has chosen a provider
 */

export const onConnect = async (data) => {
  const provider = await data.web3Modal.connect();

  await subscribeProvider(provider, data);

  await provider.enable();

  const web3 = new Web3(provider);

  const accounts = await web3.eth.getAccounts();

  const address = accounts[0];

  const networkId = await web3.eth.net.getId();

  const chainId = await web3.eth.getChainId();



  await data.setState({
    web3,
    provider,
    connected: true,
    address,
    chainId,
    networkId,
    mintTransaction: "",
    pendingRequest: false,
    txn: "",
    finish: false,
    tier: 0,
    balance:null,
    modalDialogTitle: "",
    modalDialogBodyText: "",
    containedModalShow: "",
    mintErrorMsg: "",
    isWhiteList: false,
    proof:[],
  });
  balanceOfCryptoAngel(data);
};

const subscribeProvider = async (provider, data) => {
  if (!provider.on) {
    return;
  }
  provider.on("close", () => data.state.resetApp());
  provider.on("accountsChanged", async (accounts) => {
    //getTree(data);
    await data.setState({
      address: accounts[0],
      tier: 0,
    });
    balanceOfCryptoAngel(data);

  });
  provider.on("chainChanged", async (chainId) => {
   // getTree(data);
     const { web3 } = data.state;
    const networkId = await web3.eth.net.getId();
    await data.setState({
      chainId, networkId,
    });
    balanceOfCryptoAngel(data);

  });

  provider.on("networkChanged", async (networkId) => {
    //getTree(data);

     const { web3 } = data.state;
    const chainId = await web3.eth.chainId();
    await data.setState({
      chainId, networkId,
    });
  });
  balanceOfCryptoAngel(data);
};

