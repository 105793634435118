// Set smart contract based on chain id
export const CONTRACTADDRESS = {
    69: ["10x375e1114FEf565e7FD343D25f39C4bB1893dCb8f","10x129cC008Dc7038406d9e49745cF7dE15023574f0"],
    3: "",
    10: ["0x375e1114FEf565e7FD343D25f39C4bB1893dCb8f","0x395D3b404e2C41923bD3069e9c22BD0d46648977"],
};

// Set the price of your NFT
export const PRICE = 0.002;

// Set the price of your NFT
export const REWARD_PRICE = 0.00043;

// Set the max amount of your NFT collection
export const MAX_AMOUNT = 10000;

// Set the max batch size per wallet 
export const MAX_BATCH_SIZE = 100;

// Set the white list price of your NFT
export const WHITE_LIST_PRICE = 0;

// Set the white list max batch size per wallet 
export const WHITE_LIST_NUM = 1;

// Url of network 

export const RPC_URL = "https://opt-mainnet.g.alchemy.com/v2/e3t6yYGDbS2KNY0KSym4BOAOManSPhQf";
//export const RPC_URL = "https://mainnet.optimism.io";
//export const RPC_URL = "https://kovan.optimism.io/";
//export const RPC_URL = "https://data-seed-prebsc-1-s3.binance.org:8545";

//Url of id
export const CHAIN_ID = 10;
//export const CHAIN_ID = 69;
//export const CHAIN_ID = 97;

// Set the opesnea url 
export const OPENSEA_URL = "https://tofunft.com/"

export const PLATFORM = "tofunft"

export const BASE_TOKEN_URI = "ipfs://QmQ9ta56se8bb7KpP5Mwc7VCA54JCxn8CwyU9XcDL4VVGr/"

export const BASE_MINER_URI = "ipfs://QmTyacz9nVrkdvfZiNFeEZpHRkgAPed2uM5MHiRipHfPki/"

export const BASE_ROCKET_URI = "ipfs://Qmb97yftkvcSFJTXoYAdTjFnhgSniEjt5Nb3t8pp9t3pcz/"