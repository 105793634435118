import React from "react";

import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Slide from '@mui/material/Slide';

import "./component.css"
import { formatTxn } from "../utils/util";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalDialog = (props) => {
  const { onHide } = props;
  const handleClose = () => {
    onHide();
  };

  
  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={props.show}
        TransitionComponent={Transition}
      >
        <DialogTitle  className={"dialog"}>Mint CryptoAngel</DialogTitle>
        <Card  className={"dialog"} sx={{ minWidth: 475 }}>
          <CardContent className={"dialog"}>
            <Typography  className={"dialog"}>
              {props.showPendingIcons && (
                <CircularProgress />
              )}
              <p>{props.bodyText}</p>
              {props.bodyHref ? (
                <span>
                  Txn Details:{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={props.bodyHref}
                  >
                    {formatTxn(props.bodyTxn)}
                  </a>
                </span>
              ) : (
                ""
              )}
            </Typography>
          </CardContent>
        </Card>
      </Dialog>
    </div>
  );
};
