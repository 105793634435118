import { abi } from "./abi";
import {
    MINTED_CHECK_CAP,
} from "./const";
import {
    CONTRACTADDRESS,RPC_URL,CHAIN_ID,MAX_AMOUNT,MAX_BATCH_SIZE
} from "./configuration";
import Web3 from "web3";
import { getTree } from "./merkleTree";

var interId;
var processId;
var num=0;
var diff=800;
var temp;
var isGetBalance  = false;

export const getMintedRecur = async (data) => {
    try {
    //    // getTree(data);
       getMinted_move(data);

        if (interId) {
            clearInterval(interId);
        } else {
            interId = setInterval(async function () {

                  getMinted(data);
                  //  getPercentageOfMint(data);
                //  balanceOfCryptoAngel(data);
                   // getTree(data);
            }, MINTED_CHECK_CAP);
        }

    } catch (err) {
        console.error(err.message);
    }
};


export const getPercentageOfMint = async (data) => {
    try {
        var percentage = ((data.state.mintedNum/MAX_AMOUNT)*100).toFixed(6)+"%"
        data.setState({
            percentage:percentage
        })

    } catch (err) {
        console.error(err.message);
    }
};

export const getMintStatus = async (data) => {
    if (!data.state.web3) {
        throw new Error("Error: Please connect correct wallet.");
    } else {
        const targetContract = CONTRACTADDRESS[CHAIN_ID][0];
        const web3 = new Web3(RPC_URL)
        const contract = new web3.eth.Contract(abi, targetContract)

        var isPublic = false;
        try {
            isPublic = await contract.methods.mintIsActive().call((err, result) => {
                if (err) {
                    return false;
                }
                return result;
            });
        } catch (err) {
            isPublic = false;
        }
        if (isPublic) {
            return "public";
        } else {
            return "";
        }
    }
};

export const getWhiteListMintStatus = async (data) => {
    if (!data.state.web3) {
        throw new Error("Error: Please connect correct wallet.");
    } else {
        const targetContract = CONTRACTADDRESS[CHAIN_ID][0];
        const web3 = new Web3(RPC_URL)
        const contract = new web3.eth.Contract(abi, targetContract)

        var isPublic = false;
        try {
            isPublic = await contract.methods.whiteListMintIsActive().call((err, result) => {
                if (err) {
                    return false;
                }
                return result;
            });
        } catch (err) {
            isPublic = false;
        }
        if (isPublic) {
            return "public";
        } else {
            return "";
        }
    }
};


export const getMinted = async (data) => {
    //从默认网络获取mint数据
    if (!data.state.web3) {
        throw new Error("Error: Please connect correct wallet.");
    } else {
            const targetContract = CONTRACTADDRESS[CHAIN_ID][0];
            const web3 = new Web3(RPC_URL)
            const contract = new web3.eth.Contract(abi, targetContract)
        try{
            contract.methods.totalSupply().call((err, result) => {
                if (err) {
                    console.error("Error: ", err);
                }else{
                data.setState({
                    mintedNum:result,
                    percentage:((result/MAX_AMOUNT)*100).toFixed(6)+"%"

                });
                num = result;
            }
            });
            } catch (err) {
        console.error("Error: ", err);
    }
    }
};
export const getMinted_move = async (data) => {
    //从默认网络获取mint数据
    if (!data.state.web3) {
        throw new Error("Error: Please connect correct wallet.");
    } else {
            const targetContract = CONTRACTADDRESS[CHAIN_ID][0];
            const web3 = new Web3(RPC_URL)
            const contract = new web3.eth.Contract(abi, targetContract)
        try{
            contract.methods.totalSupply().call((err, result) => {
                if (err) {
                    console.error("Error: ", err);
                }else{
                temp = result
            }
            });
            num=0;
             diff=800;             
               processId = setInterval(async function () {
                    num=num+diff
                    diff = (diff-50)>=100?diff-100:100
                    if(num>=temp){
                        num=temp
                        data.setState({
                            percentage:((num/MAX_AMOUNT)*100).toFixed(6)+"%",
                            mintedNum:num
                        });
                        if(num>1)
                        {
                        clearInterval(processId);
                        }

                    }
                    data.setState({
                        percentage:((num/MAX_AMOUNT)*100).toFixed(6)+"%",
                        mintedNum:num
                    });
                }, 400);
            
            } catch (err) {
        console.error("Error: ", err);
    }
    }
};


export const balanceOfCryptoAngel = async (data) => {
    if (!data.state.web3) {
        throw new Error("Error: Please connect correct wallet.");
    } else {
        if(data.state.address!=="")
        {
        const targetContract = CONTRACTADDRESS[CHAIN_ID][0];
        const web3 = new Web3(RPC_URL)
        var contract = new web3.eth.Contract(abi, targetContract);
        var address =  data.state.address;
        
        try{
        contract.methods.balanceOf(address).call((err, result) => {
            if (err) {
                console.error("Error: ", err);
            }else{
            data.setState({
                balance: result,
            });
            // if(isGetBalance===false){
            //     isGetBalance = true
            //     data.setState({
            //         mintAmout: (MAX_BATCH_SIZE-result)>=1?(MAX_BATCH_SIZE-result):1
            //     });
            // }
        }
        });
     } catch (err) {
            console.error("Error: ", err);
        }
    }
    }
};